<script>
import IconColorMixin from '@/mixins/IconColorMixin';

export default defineNuxtComponent({
  mixins: [IconColorMixin],
  props: {
    iconName: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    accent: {
      type: String,
      default: 'currentColor',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
      fill="white"
    />
    <path
      d="M4 1H16V-1H4V1ZM19 4V16H21V4H19ZM16 19H4V21H16V19ZM1 16V4H-1V16H1ZM4 19C2.34315 19 1 17.6569 1 16H-1C-1 18.7614 1.23858 21 4 21V19ZM19 16C19 17.6569 17.6569 19 16 19V21C18.7614 21 21 18.7614 21 16H19ZM16 1C17.6569 1 19 2.34315 19 4H21C21 1.23858 18.7614 -1 16 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
      fill="#222222"
      fill-opacity="0.16"
    />
  </svg>
</template>
